@import url("https://fonts.googleapis.com/css2?family=Exo:wght@200;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Metal+Mania:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-color: #090029;
  line-height: 1.35;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Exo", sans-serif;
  letter-spacing: 0.02em;
  @apply text-indigo-100;
}

.zombie-font {
  font-family: "Metal Mania", sans-serif;
}

.exo-font {
  font-family: "Exo", sans-serif;
}

.landing-bg {
  background: url("../images/body-bg.webp") repeat-y top/cover;
}

.top-block {
  background: url("../images/hero-bg1.webp") no-repeat top/contain;
}

.title-shadow {
  text-shadow: 3px 3px 0 #893ebb;
}

.active {
  @apply border-solid border-2 border-purple-500 text-purple-500;
}

.landing-bg .active {
  @apply bg-transparent;
}

/* Pagination */
.page-item,
.page-item.active {
  @apply inline-block bg-transparent mr-2 font-semibold;
}

.page-item .page-link {
  @apply inline-block w-9 h-9 pt-2 rounded-full;
}

.page-item.active .page-link {
  @apply bg-[#065e88];
}

.timeline-body {
  width: 100%;
  position: relative;
  z-index: 2;
}

.mint-success {
  background: #34336d;
  cursor: pointer;
}

.mint-success:hover {
  background-color: #373577;
}

#landing a,
a.link {
  color: orange;
}

#landing a:hover,
a.link:hover {
  border-bottom-color: orange;
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.move-discovery-icon {
  -webkit-animation: 3s linear 0s infinite alternate move_discovery_icon;
  -moz-animation: 3s linear 0s infinite alternate move_discovery_icon;
  animation: 3s linear 0s infinite alternate move_discovery_icon;
}

@keyframes move_discovery_icon {
  0% {
    left: 20%;
    top: 20%;
  }
  25% {
    left: 60%;
    top: 20%;
  }
  50% {
    left: 20%;
    top: 56%;
  }
  75% {
    left: 50%;
    top: 40%;
  }
  100% {
    left: 20%;
  }
}

.rotate-card {
  -webkit-animation: 2s ease-in 0s 1 alternate rotate_zoom_card;
  -moz-animation: 2s ease-in 0s 1 alternate rotate_zoom_card;
  animation: 2s ease-in 0s 1 alternate rotate_zoom_card;
  transform-style: preserve-3d;
}

@keyframes rotate_zoom_card {
  80% {
    transform: rotateZ(360deg) scale(0.5, 0.5);
    opacity: 0.3;
  }
  100% {
    opacity: 0.1;
    transform: rotateZ(500deg) scale(0.2, 0.2);
  }
}

.rotate-card-reverse {
  -webkit-animation: 0.6s ease-in 0s 1 alternate rotate_zoom_card_reverse;
  -moz-animation: 0.6s ease-in 0s 1 alternate rotate_zoom_card_reverse;
  animation: 0.6s ease-in 0s 1 alternate rotate_zoom_card_reverse;
  transform-style: preserve-3d;
}

@keyframes rotate_zoom_card_reverse {
  0% {
    transform: rotateZ(0deg) scale(0, 0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: rotateZ(-360deg) scale(1, 1);
  }
}

.discovery-text-results {
  animation: 0.4s ease-in 0s 1 alternate move_left_text;
  transform-style: preserve-3d;
}

@keyframes move_left_text {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 360px;
  }
}

.heading-tertiary {
  font-size: 1.2rem;
  font-weight: bolder;
  color: inherit;
}

.staking-monster-light {
  box-shadow: 0px 0px 20px 5px rgba(0, 255, 255, 0.3);
}

.staking-monster-bg {
  background: url("../../assets/images/staking-monster.png") no-repeat center;
  background-size: cover;
}

.text-shadow {
  text-shadow: 0 0 2px rgba(20, 15, 56, 0.6);
}

.battleTabCounter {
  @apply text-sm font-bold bg-blue-500 ml-1 px-2 py-0.5 text-white rounded;
}

.text-amber-600 .battleTabCounter {
  @apply bg-amber-600;
}

.wallet-options-wrapper {
  margin-bottom: 0 !important;
}

.options-list-section:first-child {
  margin-bottom: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  gap: 0 !important;
}

.nws-modal-wrapper .nws-modal .modal-left {
  padding: 24px !important;
}

.nws-modal-overlay {
  background: rgba(9, 0, 41, 0.7) !important;
}

.one-battle {
  background: rgb(21, 11, 73);
  background: linear-gradient(
    90deg,
    rgba(21, 11, 73, 1) 0%,
    rgba(10, 1, 44, 1) 100%
  );
}

.win-lose {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 64px 40px;
  border-color: transparent transparent rgb(239 68 68) transparent;
  position: absolute;
  left: -40px;
  top: 0;
  z-index: 2;
}

.bg-green-500 .win-lose {
  border-color: transparent transparent rgb(34 197 94) transparent;
}

.options-list-section-header {
  display: none;
}

.options-list-section {
  margin-bottom: 0 !important;
}
