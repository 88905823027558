.App {
  font-family: sans-serif;
  text-align: center;
}

.hex {
  cursor: pointer;
}

.hex p {
  background: #bcb0ff;
  transition: all ease 0.2s;
}

.hex.selected p {
  background: #ffcd4f;
}

/*.hex:hover p {*/
/*  background: #ea590a;*/
/*  !*border-color: #ea590a !important;*!*/
/*}*/

/*.hex:active p {*/
/*  border-color: #faf !important;*/
/*}*/

.board-gradient {
  position: relative;
  overflow: hidden;
}

.board-gradient:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 190px;
  z-index: 1;
  background: linear-gradient(90deg, rgba(29, 17, 94, 0) 0%, rgba(29, 17, 94, 0.8) 60%, rgba(29, 17, 94, 0.95) 90%, rgba(29, 17, 94, 1) 100%);
}

.board-left-gradient:after {
  left: 0;
  right: auto;
  background: linear-gradient(-90deg, rgba(29, 17, 94, 0) 0%, rgba(29, 17, 94, 0.8) 60%, rgba(29, 17, 94, 0.95) 90%, rgba(29, 17, 94, 1) 100%);
}